import React from "react";

// React
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// Heroicons
import { ChevronDownIcon, ArrowNarrowDownIcon } from "@heroicons/react/outline";

// SVGs
import AritaeLogo from "../images/logo/logo.inline.svg";
import AritaeLogoLetters from "../images/logo/logo-letters.inline.svg";

// Shared Components
import Footer from "../components/shared/footer/Footer";
import Header from "../components/shared/header/Header";

const faqs = [
  {
    question: "Where are you located?",
    answer:
      "We are headquartered in Skaneateles, New York, but our coaches live all over the country.  While much of our program is done virtually, there will be opportunities to meet with ARITAE Coaches in person. We hold in-person training events and tournaments around the country - stay tuned for a link to our in-person events this summer!",
  },
  {
    question: "Who will my ARITAE Coach be?",
    answer:
      "Once you have decided that you are ready to take the steps to reach your full potential, you will be asked to complete an ARITAE Leadership Academy application. Your completed application will be reviewed by our ARITAE Coaching Leadership Team, and we will pair you up with the ARITAE Coach who is the best match for you and your goals.",
  },
  {
    question: "Can I take the ARITAE Leadership Academy more than once?",
    answer:
      "Once you complete the entire ARITAE Leadership program successfully, you will not need to re-take the program because you will have learned all you need to know, and you will still have the mobile app on your phone. With the ARITAE app, you will always be able to set new goals and use the ARITAE Leadership program to help you set and reach those goals. As you set new goals or come to new crossroads in life, you can also benefit from additional one-on-one time with your ARITAE Coach.",
  },
  {
    question: "What is the price of the ARITAE Leadership program?",
    answer:
      "The price of attending ARITAE Leadership Academy varies depending on if you'd like to have Individualized Coaching or Group Coaching. Please reach out to us and we will be happy to send you information on the costs of the particular ARITAE Leadership program you are interested in pursuing.",
  },
  {
    question: "Will I get a certificate?",
    answer:
      "When you complete the ARITAE Leadership Academy, you will receive a certificate of completion. We highly suggest that you keep an electronic copy of the certificate to attach to job or college applications in the future.",
  },
  {
    question: "How will I be able to access the program?",
    answer:
      "The ARITAE Leadership program is available online, via our website or the ARITAE app. You will be able to work through the steps of the program on your computer or phone. In some cases (if you are meeting an ARITAE Coach in person, or if you prefer to work on paper) you may be given an ARITAE Playbook. You will create a login for your account, where you can access your personalized ARITAE Leadership program and work on the modules at your convenience.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FAQ() {
  const [largeScreen, setLargeScreen] = useState(true);

  //ADD LISTENER TO CHECK THE SCREEN SIZE
  function checkScreenSize() {
    if (window.innerWidth <= 768) {
      setLargeScreen(false);
    } else {
      setLargeScreen(true);
    }
  }

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const HeaderImage = cld
    .image("v1646062130/Aritae/Faq/faq-trees-background_dgt3kk.jpg")
    .quality("auto");
  const HeaderMobile = cld
    .image("v1646076938/Aritae/Faq/faq-trees-background-mobile_sshudn.jpg")
    .quality("auto");

  return (
    <div>
      <Header />
      {/* START HERO SECTION */}
      <div className="h-screen relative">
        <div className="absolute h-screen top-0 inset-0">
          <AdvancedImage
            plugins={[responsive(200)]}
            cldImg={largeScreen ? HeaderImage : HeaderMobile}
            alt="many trees in forest looking upward toward sun shining bright in blue sky"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="relative">
          <div className="flex flex-col h-screen items-center justify-center">
            <h1 className="text-center text-4xl font-extrabold sm:text-5xl lg:text-6xl">
              <span className="block text-white text-2xl pb-6">FAQ</span>
            </h1>
            <div className={"flex flex-col items-center justify-center my-12"}>
              <AritaeLogo
                fill={"white"}
                className={"h-32 w-auto mx-auto pb-2"}
              />
              <AritaeLogoLetters
                fill={"white"}
                className={"h-auto w-48 mx-auto"}
              />
            </div>
            <p
              className={
                "text-white max-w-3xl px-16 text-center font-bold text-2xl mt-4 uppercase pb-3"
              }
            >
              At ARITAE, we believe that the first step in building leadership
              is to help our players understand their “why.”
            </p>
            <p
              className={
                "text-white max-w-3xl px-16 text-center font-bold text-lg mt-4 uppercase"
              }
            >
              “What is necessary to change a person is to change his awareness
              of himself.” Abraham Maslow
            </p>
            <div className="hidden lg:flex mt-10 max-w-sm mx-auto justify-center">
              <ArrowNarrowDownIcon className={"h-7 text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/* END HERO SECTION */}
      <div className="bg-forest flex justify-center py-12">
        {/* FAQ Section */}
        <div className="">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-center text-3xl font-extrabold text-white pb-12">
                Frequently asked questions
              </h2>
              {faqs.map((faq) => (
                <Disclosure
                  as="div"
                  key={faq.question}
                  className="pt-6 bg-gray-100 rounded-md m-5 p-5 max-w-3xl"
                >
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="w-full flex flex-row justify-between text-left text-gray-800">
                          <span className="font-extrabold text-gray-800 max-w-3xl">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base max-w-md text-gray-800">
                          {faq.answer}
                        </p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
